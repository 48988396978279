// transfer_type  0:カスタム 1:同行同店 2:同行他店 3:他行
const bankTransferTypeList = [
  { value: 1, text: '同行同店', color: 'blue-500' },
  { value: 2, text: '同行他店', color: 'green-500' },
  { value: 3, text: '他行', color: 'red-500' },
  { value: 0, text: 'カスタム', color: 'yellow-800' },
]

// applied_condition_type
// 条件のタイプ 0:下限値以上and上限値以下 1:下限値以上 2:上限値以下
const appliedConditionTypeList = [
  { value: 1, text: '指定額以上' },
  { value: 2, text: '指定額未満' },
  { value: 0, text: '範囲指定' },
]

const transferFeeForm = {
  user_bank: {},
  transfer_type: 0,
  destination_bank: {},
  applied_condition_type: 1,
  applied_condition_min: 30000,
  applied_condition_lower_than: 30000,
  applied_condition_max: 29999,
  transfer_fee: 0,
}

export function getBankTransferTypeList() {
  return JSON.parse(JSON.stringify(bankTransferTypeList))
}

export function getAppliedConditionTypeList() {
  return JSON.parse(JSON.stringify(appliedConditionTypeList))
}

export function getTransferFeeForm() {
  return JSON.parse(JSON.stringify(transferFeeForm))
}
