<template>
  <v-dialog v-model="dialog" :max-width="maxWidth">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="computedText.title" />
      <v-card-text>
        <v-form ref="formEdit">
          <slot name="form">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="transferFeeForm.transfer_type"
                  :items="bankTransferTypeList"
                  label="区分"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  filled
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="transferFeeForm.user_bank"
                  :items="userBankList"
                  :rules="rulesIdRequired"
                  label="振込元銀行"
                  item-text="fulltext"
                  item-value="id"
                  dense
                  outlined
                  filled
                  return-object
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-autocomplete
                  v-model="transferFeeForm.destination_bank"
                  :items="bankList"
                  :disabled="diabledDestinationBankName"
                  :rules="computedDestinationBankRule"
                  label="振込先銀行"
                  item-text="name_code"
                  item-value="name"
                  dense
                  outlined
                  filled
                  clearable
                  return-object
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="py-0">
                <v-autocomplete
                  v-model="transferFeeForm.applied_condition_type"
                  :items="appliedConditionTypeList"
                  label="適用条件"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  filled
                />
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 0"
                cols="3"
                class="py-0"
              >
                <amount-input
                  v-model="transferFeeForm.applied_condition_min"
                  dense
                  filled
                  outlined
                />
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 0"
                cols="1"
                class="py-0"
              >
                以上
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 0"
                cols="3"
                class="py-0"
              >
                <amount-input
                  v-model="transferFeeForm.applied_condition_lower_than"
                  dense
                  filled
                  outlined
                />
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 0"
                cols="1"
                class="py-0"
              >
                未満
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 1"
                cols="6"
                class="py-0"
              >
                <amount-input
                  v-model="transferFeeForm.applied_condition_min"
                  dense
                  filled
                  outlined
                />
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 1"
                cols="2"
                class="py-0"
              >
                以上
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 2"
                cols="6"
                class="py-0"
              >
                <amount-input
                  v-model="transferFeeForm.applied_condition_lower_than"
                  dense
                  filled
                  outlined
                />
              </v-col>
              <v-col
                v-show="transferFeeForm.applied_condition_type === 2"
                cols="2"
                class="py-0"
              >
                未満
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="py-0">
                <amount-input
                  v-model="transferFeeForm.transfer_fee"
                  label="手数料"
                  dense
                  filled
                  outlined
                />
              </v-col>
            </v-row>
          </slot>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="dialog = false">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn color="primary" @click="onClick()">
          {{ $t('message.modal_save_btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getBankTransferTypeList,
  getTransferFeeForm,
  getAppliedConditionTypeList,
} from '@/objects/master/transfer_fee'
import AmountInput from '@/components/Input/AmountInput'

const bankTransferTypeList = getBankTransferTypeList()
const transferFeeForm = getTransferFeeForm()
const appliedConditionTypeList = getAppliedConditionTypeList()

export default {
  components: {
    AmountInput,
  },
  props: {
    inputs: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '700',
    },
    userBankList: {
      type: Array,
      required: true,
    },
  },
  data() {
    const t = this
    return {
      dialog: false,
      mode: 'add',
      rulesIdRequired: [(v) => !!v.id || t.$t('message.form_input_required')],
      rulesNameRequired: [
        (v) => !!v.name || '※カスタムの場合この項目は必須です',
      ],
      bankTransferTypeList,
      transferFeeForm,
      appliedConditionTypeList,
    }
  },
  computed: {
    ...mapGetters(['bankList']),
    diabledDestinationBankName: function () {
      return this.transferFeeForm.transfer_type !== 0
    },
    computedText: function () {
      let textObj = { title: '' }
      if (this.mode == 'add') {
        textObj.title = this.$t('message.add')
      } else if (this.mode == 'edit') {
        textObj.title = this.$t('message.edit')
      }
      return textObj
    },
    computedDestinationBankRule: function () {
      if (this.transferFeeForm.transfer_type == 0) {
        return this.rulesNameRequired
      } else {
        return []
      }
    },
  },
  watch: {
    'transferFeeForm.transfer_type': {
      handler: function (val) {
        try {
          if (val !== 0) {
            this.transferFeeForm.destination_bank = {}
          }
        } catch (e) {
          console.log(e)
        }
      },
      deep: true,
    },
    'transferFeeForm.applied_condition_lower_than': function (val) {
      this.transferFeeForm.applied_condition_max = val - 1
    },
  },
  methods: {
    open(mode, item) {
      this.dialog = true
      this.reset()
      this.mode = mode
      if (mode == 'edit') {
        this.setEditValue(item)
      }
    },
    close() {
      this.dialog = false
    },
    onClick() {
      if (this.validate()) {
        this.$emit('onClick', this.transferFeeForm)
      }
    },
    validate() {
      try {
        return this.$refs.formEdit.validate()
      } catch (e) {
        console.log(e)
      }
    },
    resetValidation() {
      if (this.dialog) {
        this.$nextTick(function () {
          this.$refs.formEdit.resetValidation()
        })
      }
    },
    reset() {
      this.transferFeeForm = getTransferFeeForm()
      if (this.dialog) {
        this.$nextTick(function () {
          this.$refs.formEdit.resetValidation()
        })
      }
    },
    setEditValue(item) {
      try {
        const appliedConditionLowerThan = item.applied_condition_max + 1
        const destinationBank = this.bankList.find(
          (x) => x.code == item.destination_bank_code
        )
        this.transferFeeForm = {
          user_bank: item.user_bank,
          transfer_type: item.transfer_type,
          destination_bank: destinationBank,
          applied_condition_type: item.applied_condition_type,
          applied_condition_min: item.applied_condition_min,
          applied_condition_lower_than: appliedConditionLowerThan,
          applied_condition_max: item.applied_condition_max,
          transfer_fee: item.transfer_fee,
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
