<template>
  <v-text-field
    v-model="amount"
    :label="label"
    placeholder=" "
    hide-details
    :dense="dense"
    :rules="rules"
    :filled="filled"
    :outlined="outlined"
    :readonly="readonly"
    :height="height"
    :reverse="reverse"
    :tabindex="tabindex"
  />
</template>

<script>
import { currencyToNumber, numberToCurrency } from 'Helpers/journal'

export default {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      validator: (prop) =>
        typeof prop === 'number' || prop === null || prop === '',
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    height: {
      validator: (height) =>
        ['string', 'number'].includes(typeof height) || undefined,
      default: undefined,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: '0',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    displayZero: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    amount: {
      get() {
        if (this.displayZero) {
          return numberToCurrency(this.value)
        } else if (this.value === '') {
          return ''
        }
        return numberToCurrency(this.value)
      },
      set(val) {
        const newVal = currencyToNumber(val)
        if (this.value !== newVal) {
          this.$emit('input', newVal)
        }
      },
    },
  },
}
</script>
