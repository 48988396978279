import { getBankTransferTypeList } from '@/objects/master/transfer_fee'

const bankTransferTypeList = getBankTransferTypeList()

export function getBankTransferTypeContent(value) {
  try {
    const obj = bankTransferTypeList.find((item) => item.value == value)
    if (obj) {
      return obj
    } else {
      return ''
    }
  } catch (e) {
    console.log(e)
    return ''
  }
}

export function getConditionText(obj) {
  try {
    // applied_condition_type 条件のタイプ 0:下限値以上and上限値以下 1:下限値以上 2:上限値以下
    const conditionType = obj.applied_condition_type
    const conditionMax = obj.applied_condition_max + 1
    const conditionMin = obj.applied_condition_min

    if (conditionType === 1) {
      return conditionMin + '円以上'
    } else if (conditionType === 2) {
      return conditionMax + '円未満'
    } else if (conditionType === 0) {
      return conditionMin + '円以上 ' + conditionMax + '円未満'
    }
  } catch (e) {
    console.log(e)
    return ''
  }
}
