<template>
  <div v-if="settingPaidByCustomer && checkPrivilege('transfer_fee:read')">
    <app-section-loader :status="loading_page" />
    <progress-dialog ref="progressDialog" />

    <dialog-confirm-add ref="dialogConfirmAdd" @onClick="addInstance()" />

    <dialog-edit-transfer-fee
      ref="dialogAdd"
      :inputs="inputs"
      :label="definition.journal.item_name"
      :user-bank-list="userBankList"
      @onClick="addInstance($event)"
    />

    <dialog-edit-transfer-fee
      ref="dialogEdit"
      :inputs="inputs"
      :label="definition.journal.item_name"
      :user-bank-list="userBankList"
      @onClick="submitEditInstance($event)"
    />

    <input-delete-dialog
      ref="dialogDelete"
      max-width="400"
      :title="$t('message.delete')"
      :message="$t('message.transfer_fee_delete_message')"
      :cancel-text="$t('message.modal_cancel_btn')"
      :delete-text="$t('message.table_delete_btn')"
      @submit="
        deleteInstance()
        $refs.dialogDelete.close()
      "
    />

    <transfer-fee-table
      ref="transferFeeTable"
      name="transfer_fee"
      :items="data"
      :search="search"
      :loading="loading_page"
      @click:create="openAddDialog()"
      @click:edit="openEditDialog($event)"
      @click:delete="
        selectInstance($event)
        $refs.dialogDelete.open()
      "
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Snackbar from 'Helpers/snackbar/index'
import DialogConfirmAdd from '@/pages/master/@components/@shared/DialogConfirmAdd/DialogConfirmAdd'
import DialogEditTransferFee from '@/pages/master/TransferFee/@components/@shared/DialogEditTransferFee/DialogEditTransferFee'
import TransferFeeTable from '@/pages/master/TransferFee/@components/TransferFeeTable/TransferFeeTable'

export default {
  components: {
    DialogConfirmAdd,
    DialogEditTransferFee,
    TransferFeeTable,
  },
  props: {
    definition: {
      type: Object,
      required: true,
    },
    getJournalItems: {
      type: Function,
      required: true,
    },
    getMaster: {
      type: Function,
      required: true,
    },
    exportMaster: {
      type: Function,
      required: true,
    },
    sortMaster: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      name: '振込手数料',
      loading_page: false,

      data: [],

      inputs: { code: '', name: '' },
      search: '',
      selectedInstance: {},
      userBankList: [],
    }
  },
  computed: {
    ...mapGetters(['settingPaidByCustomer'])
  },
  mounted() {
    this.getData()
    this.getUserBank()
  },
  methods: {
    getData() {
      this.loading_page = true
      this.$store.dispatch('getTransferFeeList').then((data) => {
        this.data = data
        this.loading_page = false
      })
    },
    getUserBank() {
      this.$store.dispatch('getUserBankList').then((data) => {
        this.userBankList = data
      })
    },
    submitEditInstance(item) {
      try {
        let destinationBankName = ''
        let destinationBankCode = ''
        if (item.destination_bank) {
          destinationBankName = item.destination_bank.name
            ? item.destination_bank.name
            : ''
          destinationBankCode = item.destination_bank.code
            ? item.destination_bank.code
            : ''
        }
        const editId = this.selectedInstance.id
        const transferFee = {
          id: editId,
          transfer_type: item.transfer_type,
          destination_bank_name: destinationBankName,
          destination_bank_code: destinationBankCode,
          applied_condition_type: item.applied_condition_type,
          applied_condition_min: item.applied_condition_min,
          applied_condition_max: item.applied_condition_max,
          transfer_fee: item.transfer_fee,
          user_bank_id: item.user_bank.id,
        }
        this.$store
          .dispatch('updateTransferFee', transferFee)
          .then(() => {
            let editItem = this.data.find((x) => x.id == editId)
            editItem.transfer_type = item.transfer_type
            editItem.destination_bank_name = destinationBankName
            editItem.destination_bank_code = destinationBankCode
            editItem.applied_condition_type = item.applied_condition_type
            editItem.applied_condition_min = item.applied_condition_min
            editItem.applied_condition_max = item.applied_condition_max
            editItem.transfer_fee = item.transfer_fee
            editItem.user_bank = item.user_bank
            this.$refs.dialogEdit.close()
            Snackbar.success(this.$t('message.success'))
            this.resetForm()
          })
          .catch((error) => {
            Snackbar.error(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
    deleteInstance() {
      const deleteId = this.selectedInstance.id
      this.$store.dispatch('deleteTransferFee', { id: deleteId }).then(() => {
        let index = this.data.findIndex((x) => x.id == deleteId)
        this.data.splice(index, 1)
        Snackbar.success(this.$t('message.success'))
      })
    },
    resetForm() {
      try {
        this.$refs.dialogAdd.resetValidation()
      } catch (e) {
        console.log(e)
      }
    },
    selectInstance(item) {
      this.selectedInstance = item
    },
    addInstance(item) {
      let destinationBankName = ''
      let destinationBankCode = ''
      if (item.destination_bank) {
        destinationBankName = item.destination_bank.name
          ? item.destination_bank.name
          : ''
        destinationBankCode = item.destination_bank.code
          ? item.destination_bank.code
          : ''
      }
      const transferFee = {
        transfer_type: item.transfer_type,
        destination_bank_name: destinationBankName,
        destination_bank_code: destinationBankCode,
        applied_condition_type: item.applied_condition_type,
        applied_condition_min: item.applied_condition_min,
        applied_condition_max: item.applied_condition_max,
        transfer_fee: item.transfer_fee,
        user_bank_id: item.user_bank.id,
      }
      this.$store
        .dispatch('createTransferFee', transferFee)
        .then((responseData) => {
          this.data.push({
            id: responseData.id,
            transfer_type: item.transfer_type,
            destination_bank_name: destinationBankName,
            destination_bank_code: destinationBankCode,
            applied_condition_type: item.applied_condition_type,
            applied_condition_min: item.applied_condition_min,
            applied_condition_max: item.applied_condition_max,
            transfer_fee: item.transfer_fee,
            user_bank: item.user_bank,
          })
          this.$refs.dialogAdd.close()
          Snackbar.success(this.$t('message.success'))
          this.resetForm()
        })
        .catch((error) => {
          Snackbar.error(error.message)
        })
    },
    openAddDialog() {
      this.$refs.dialogAdd.open('add')
    },
    openEditDialog(item) {
      this.selectInstance(item)
      this.$refs.dialogEdit.open('edit', this.selectedInstance)
    },
  },
}
</script>
