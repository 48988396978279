<template>
  <div>
    <v-card-title class="py-0 px-6 my-5">
      <v-btn
        v-if="checkPrivilege(name + ':create')"
        color="primary"
        dark
        @click.stop="$emit('click:create')"
      >
        <s-icon icon="feather-plus" size="lg" class="tw-mr-2" />
        {{ $t('message.add') }}
      </v-btn>
    </v-card-title>

    <v-data-table
      id="master-table"
      v-model="selected"
      class="px-6"
      :height="height"
      :items="items"
      :headers="computedHeaders"
      :search="search"
      item-key="id"
      fixed-header
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :custom-sort="customSort"
      @pagination="onPagenation"
      @page-count="pageCount = $event"
      @current-items="getfilteredItems"
    >
      <template #item.user_bank="{ item }">
        {{ item.user_bank.fulltext }}
      </template>

      <template #item.transfer_type="{ item }">
        <div class="d-flex justify-center">
          <s-chip :color="getBankTransferTypeContent(item.transfer_type).color">
            {{ getBankTransferTypeContent(item.transfer_type).text }}
          </s-chip>
        </div>
      </template>

      <template #item.destination_bank="{ item }">
        {{ getDestinationBankNameCode(item) }}
      </template>

      <template #item.condition="{ item }">
        {{ getConditionText(item) }}
      </template>

      <template #item.action="{ item }">
        <div class="d-flex justify-center">
          <v-btn
            v-if="checkPrivilege(name + ':update')"
            small
            rounded
            color="primary"
            @click="$emit('click:edit', item)"
          >
            {{ $t('message.table_edit_btn') }}
          </v-btn>
          <v-btn
            v-if="checkPrivilege(name + ':delete')"
            class="ml-2"
            small
            rounded
            color=""
            @click="$emit('click:delete', item)"
          >
            {{ $t('message.table_delete_btn') }}
          </v-btn>
        </div>
      </template>

      <template v-if="!loading" #no-data>
        <v-alert :value="true" class="mt-3" color="yellow" icon="warning">
          {{ $t('message.noResult') }}
        </v-alert>
      </template>
    </v-data-table>

    <v-toolbar
      flat
      height="60"
      class="mt-3 pl-2"
      style="width: 100%; border-top: 1px solid #ccc"
    >
      <v-col cols="6" class="pa-0">
        <v-card flat tile class="d-flex align-content-center flex-wrap">
          <v-card flat class="align-self-center mr-3">
            <div class="fs-10 mb-1">表示件数</div>
            <v-autocomplete
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              dense
              filled
              hide-details
              item-text="text"
              item-value="value"
              class="more-dense"
              style="width: 100px"
              :clearable="false"
              @change="$emit('change:items-per-page', itemsPerPage)"
            />
          </v-card>

          <v-card flat class="align-self-center mr-3">
            <div class="fs-10 mb-1">ページ</div>
            <v-autocomplete
              v-model="page"
              :items="pageCountItems"
              dense
              filled
              hide-details
              class="more-dense"
              style="width: 100px"
              :clearable="false"
            />
          </v-card>

          <v-card flat class="align-self-center">
            <div class="fs-10 mb-1">表示中</div>
            <v-chip small label style="padding: 14px">
              {{ pageInfo.start }} - {{ pageInfo.end }} / {{ itemsLength }}
            </v-chip>
          </v-card>
        </v-card>
      </v-col>

      <v-col cols="6" class="text-right pa-0">
        <v-pagination
          v-model="page"
          circle
          light
          style="width: auto"
          :length="pageCount"
        />
      </v-col>
    </v-toolbar>
  </div>
</template>

<script>
import { scrollTableToTop } from '@/helpers/table/scroll'
import {
  getBankTransferTypeContent,
  getConditionText,
} from '@/services/master/transfer_fee'

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'calc(100vh - 240px)',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { width: 350, text: '振込元銀行', value: 'user_bank' },
        { width: 100, text: '区分', value: 'transfer_type' },
        {
          width: 200,
          text: '振込先銀行',
          value: 'destination_bank',
          sortable: false,
        },
        { width: 200, text: '金額条件', value: 'condition', sortable: false },
        { width: 100, text: '手数料', value: 'transfer_fee' },
        { text: '', value: 'action', sortable: false },
      ],
      page: 1,
      itemsPerPage: 50,
      pageCount: 0,
      itemsLength: 0,
      pageInfo: {
        start: 0,
        end: 0,
      },
      itemsPerPageOptions: [
        { value: 50, text: '50件' },
        { value: 100, text: '100件' },
        { value: 200, text: '200件' },
      ],
      selected: [],
      filteredItems: [],
      selectedItem: {},
    }
  },
  computed: {
    pageCountItems() {
      return Array.from({ length: this.pageCount }, (v, k) => k + 1)
    },
    computedHeaders: function () {
      const resource = 'transfer_fee'
      const hasEditCol =
        this.checkPrivilege(`${resource}:update`) ||
        this.checkPrivilege(`${resource}:delete`)
      if (hasEditCol) {
        return this.headers
      } else {
        return this.headers.slice(0, this.headers.length - 1)
      }
    },
  },
  methods: {
    onPagenation(pageInfo) {
      this.itemsLength = pageInfo.itemsLength
      this.pageInfo.start = pageInfo.pageStart + 1
      this.pageInfo.end = pageInfo.pageStop
      scrollTableToTop('master-table')
    },
    getfilteredItems(event) {
      this.filteredItems = event
    },
    getBankTransferTypeContent(value) {
      return getBankTransferTypeContent(value)
    },
    getConditionText(obj) {
      return getConditionText(obj)
    },
    getDestinationBankNameCode(item) {
      if (
        item.destination_bank_name !== '' &&
        item.destination_bank_code !== ''
      ) {
        return (
          item.destination_bank_name + ' (' + item.destination_bank_code + ')'
        )
      } else if (item.destination_bank_name !== '') {
        return item.destination_bank_name
      } else {
        return ''
      }
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'user_bank') {
          if (!isDesc[0]) {
            return a.user_bank.id < b.user_bank.id ? -1 : 1
          } else {
            return b.user_bank.id < a.user_bank.id ? -1 : 1
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
  },
}
</script>
