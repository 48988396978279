export function scrollTableToTop(elementId) {
  try {
    let target = document.getElementById(elementId)
    if (!target) {
      return
    }
    let targetScrollTable = target.getElementsByClassName(
      'v-data-table__wrapper'
    )[0]
    if (targetScrollTable) {
      targetScrollTable.scrollTop = 0
    }
  } catch (e) {
    console.log(e)
  }
}
