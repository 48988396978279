<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="!duplicateItems.code.length ? '確認' : 'エラー'" />

      <v-card-text>
        <template v-if="!duplicateItems.code.length">
          <div
            v-show="duplicateItems.name.length"
            v-text="'登録済みの名称と重複しています。'"
          />
          <div
            v-show="getLen(duplicateItems, 'resport_data__local_bank_code')"
            v-text="'登録済みの管理者用口座情報コードと重複しています。'"
          />
        </template>

        <template v-else>
          <div
            v-show="duplicateItems.code.length"
            v-text="'登録済みのコードと重複しています。'"
          />
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click.native="close()"
          v-text="$t('message.modal_cancel_btn')"
        />
        <v-btn
          v-show="!duplicateItems.code.length"
          color="primary"
          @click="onClick"
          @click.native="close()"
          v-text="'追加'"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      duplicateItems: { all: [], name: [], code: [] },
    }
  },
  mounted() {},
  methods: {
    open() {
      this.dialog = true
    },
    set(items) {
      this.duplicateItems = items
    },
    getLen(obj, key) {
      try {
        return obj[key].length
      } catch (e) {
        return 0
      }
    },
    close() {
      this.dialog = false
      this.duplicateItems = { all: [], name: [], code: [] }
    },
    onClick() {
      this.$emit('onClick')
    },
  },
}
</script>
